.componentBase {
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-base);
  line-height: 1;
  padding: 0;
}

.subtitle {
  font-weight: var(--font-weight-light);
}

.componentHeaderSubtitleXl {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-32);
  color: var(--color-blue-700);
}

.componentHeaderSubtitleLg {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-32);
}

.componentHeaderSubtitleMd {
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.componentHeaderTitleLg {
  font-size: var(--font-size-32);
  color: var(--color-blue-700);
  font-family: var(--font-family-heading);

  @media (--viewport-md) {
    font-size: var(--font-size-52);
  }
}

.componentHeaderTitleMd {
  font-size: var(--font-size-24);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.componentHeaderTitleSm {
  font-size: var(--font-size-18);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.componentHeaderTitleXl {
  font-size: var(--font-size-48);
  font-family: var(--font-family-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}

.componentXl {
  & > .subtitle {
    margin-top: var(--size-12);

    @media (--viewport-md) {
      margin-top: var(--size-24);
    }
  }
}

.componentSmAlt {
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);
}
