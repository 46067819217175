.componentIconBase {
  padding: var(--size-10) var(--size-22) var(--size-10) var(--size-16);
  background-color: var(--color-blue-500);
  color: var(--color-white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-base);
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .text {
    margin-left: var(--size-10);
  }
}

.icon {
  display: flex;
  align-items: center;
}
